@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.emoji-seed-demo > *:first-child,.emoji-seed-demo > *:last-child { top:-1rem; }
.tooltip.tooltip-hidden:hover:after, .tooltip.tooltip-hidden:hover:before { opacity:0; }

/* ----------------------------------------------
 * Generated by Animista on 2022-2-26 23:16:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
.jello-horizontal{animation:jello-horizontal .9s both}
.jello-diagonal-1{animation:jello-diagonal-1 .8s both}
.puff-out-center{animation:puff-out-center 1s cubic-bezier(.165,.84,.44,1.000) both}
.shake-horizontal{animation:shake-horizontal .8s cubic-bezier(.455,.03,.515,.955) both}
@keyframes jello-horizontal{0%{transform:scale3d(1,1,1)}30%{transform:scale3d(1.25,.75,1)}40%{transform:scale3d(.75,1.25,1)}50%{transform:scale3d(1.15,.85,1)}65%{transform:scale3d(.95,1.05,1)}75%{transform:scale3d(1.05,.95,1)}100%{transform:scale3d(1,1,1)}}
@keyframes jello-diagonal-1{0%{transform:skew(0deg 0deg)}30%{transform:skew(25deg 25deg)}40%{transform:skew(-15deg,-15deg)}50%{transform:skew(15deg,15deg)}65%{transform:skew(-5deg,-5deg)}75%{transform:skew(5deg,5deg)}100%{transform:skew(0deg 0deg)}}
@keyframes puff-out-center{0%{transform:scale(1);filter:blur(0);opacity:1}60%{transform:scale(2);filter:blur(4px);opacity:.5}100%{transform:scale(1);filter:blur(0px);opacity:.5}}
